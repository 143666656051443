<template>
  <div class="MyCascader">
    <el-cascader
      :value="value"
      :options="options"
      :props="propsItem"
      clearable
      popper-class="MyCascaderSelectClass"
      ref="MyCascaderRef"
      @change="handleChange"
      :placeholder="placeholder"
    ></el-cascader>
  </div>
</template>

<script>
export default {
  name: 'MyCascader',
  props: {
    options: {
      type: Array,
      required: true
    },
    value: {
      type: Array,
      required: true
    },
    propsItem: {
      type: Object,
      default: () => {
        return {
          // 次级展开方式 click / hover
          expandTrigger: 'hover',
          // 是否能选择末尾节点外的节点
          checkStrictly: true,
          // 指定options里对象的某个属性值为选项的值 例如
          value: 'id',
          // 指定选项标签为选项对象的某个属性值
          label: 'categoryName'
        }
      }
    },
    placeholder: {
      type: String,
      default: '请输入'
    }
  },
  methods: {
    handleChange(val) {
      // console.log('val', val)
      this.$emit('update:value', val)
      // 数据变化后收起下拉菜单
      this.$refs.MyCascaderRef.dropDownVisible = false
    }
  }
}
</script>
<style lang="scss">
.MyCascaderSelectClass {
  .el-cascader-panel .el-radio {
    width: 100%;
    height: 100%;
    z-index: 10;
    position: absolute;
    top: 0px;
    right: 0px;
  }
  .el-cascader-panel .el-radio__input {
    visibility: hidden;
  }
}
</style>
<style scoped lang="scss"></style>
